
.checkers-entity-piece, .checkers-entity-king {
  background: black;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.checkers-entity-playera, .checkers-entity-playerb {
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
}

.checkers-entity-playera {
  background-image: url("../../images/tesla-white.png");
  background-color: red;
}

.checkers-entity-playerb {
  background-image: url("../../images/gm-white.png");
  background-color: #1c549f;
  background-size: 1.5rem 1.5rem;
}

.checkers-entity-playera {
  cursor: move;
}

.checkers-entity.checkers-entity-king {
  background-image: url("../../images/crown.png");
  background-size: 2rem 2rem;
}

@media screen and (max-width: 800px) {
  .checkers-entity-piece, .checkers-entity-king {
    width: 8vw;
    height: 8vw;
    padding: .125rem;
    box-sizing: border-box;
  }

  .checkers-entity-playera, .checkers-entity-playerb {
    background-size: 5vw;
  }

  .checkers-entity-playerb {
    background-size: 4vw;
  }

  .checkers-entity.checkers-entity-king {
    background-size: 5vw;
  }
}
